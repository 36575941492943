/* eslint-disable */
import { ActionContext } from "vuex";
import { ActionTypeId, WebSocketEvent } from "../../../common/src/enums";
import { OTALogOrm } from "../../../common/src/models/postgres/otaLog";
import { NotificationItem } from "./notification";
import { JCLoadFileOrm, JCManagedPackageAIDOrm } from "../../../common/src/models/postgres/jcLoadFile";
import { ClassificationLogItemType } from "../../../server/demo-aicamera/src/common/types";

interface State {
  connect: boolean;
  socket: WebSocket | null;
}

interface BasePayload {
  event: WebSocketEvent;
}

interface SimUpdatedPayload {
  event: WebSocketEvent.SimUpdated;
  sim: any;
}

interface RecievedSimDataPayload {
  event: WebSocketEvent.SimDataRecieved;
  logAction: any;
}

interface OTALog extends Omit<OTALogOrm, 'createdAt'> {
  createdAt: string;
}

interface OTALogPayload {
  event: WebSocketEvent.OTALog;
  otaLog: OTALog;
  simId: number;
  loadFile: JCLoadFileOrm;
  managedPackageLabel: string;
}

export default {
  namespaced: true,
  state: {
    socket: null,
    connect: false,
  },
  getters: {},
  mutations: {
    SET_SOCKET: (state: State, payload: WebSocket | null) => (state.socket = payload),
    SET_CONNECT: (state: State, payload: boolean) => (state.connect = payload),
  },
  actions: {
    connect: ({ commit, state, dispatch, rootGetters }: ActionContext<State, any>, payload?: { reconnectWait: number, env?: string }) => {
      console.debug(new Date(), "websocket/connect", payload);

      if (state.socket) {
        console.warn("websocket already connected");
        return;
      }

      let url = process.env.VUE_APP_WS_BASE_URL;
      if (!url && process.env.VUE_APP_WS_BASE_DOMAIN && payload?.env) {
        url = `wss://ws.${payload.env}.${process.env.VUE_APP_WS_BASE_DOMAIN}`;
      }

      if (!url) {
        console.debug("environment variable 'VUE_APP_WS_BASE_URL' or 'VUE_APP_WS_BASE_DOMAIN' are undefined. skip connect websocket.");
        return;
      }

      commit("SET_CONNECT", true);
      console.debug(new Date(), "websocket/connect", "open websocket");
      const socket = new WebSocket(`${url}?token=${rootGetters.getToken}`);
      commit("SET_SOCKET", socket);

      if (!payload) {
        payload = {
          reconnectWait: 500,
        };
      }

      socket.addEventListener("open", () => {
        console.debug(new Date(), "websocket/connect", "websocket connected");
        payload!.reconnectWait = 500;
        // NOTE: NOOP
      });
      socket.addEventListener("close", () => {
        console.debug(new Date(), "websocket/connect", "websocket closed");
        if (!state.connect) {
          return;
        }
        commit("SET_SOCKET", null);
        if (payload!.reconnectWait <= 4000) {
          payload!.reconnectWait *= 2;
        }
        setTimeout(() => dispatch("connect", payload), payload!.reconnectWait);
      });
      socket.addEventListener("message", (event) => {
        const data: BasePayload = JSON.parse(event.data);
        if (!data) {
          return;
        }

        switch (data.event) {
          case WebSocketEvent.SimUpdated:
            dispatch("sim/updateByWebSocket", { sim: (data as SimUpdatedPayload).sim }, { root: true });
            break;
          case WebSocketEvent.SimDataRecieved:
            const logAction = (data as RecievedSimDataPayload).logAction;
            dispatch("recievedSimData/addRecievedSimData", logAction, { root: true });
            // 受信データを notification list に追加
            const requestParams = typeof logAction.request_params === "string" ? JSON.parse(logAction.request_params) : logAction.request_params;
            const item = {
              date: logAction.created_at,
              title: "RecievedSimData",
              body: `ICCID: ${requestParams.iccid}\nimsi: ${requestParams.imsi}`,
              to: `/sims/${requestParams.id}/${requestParams.iccid}`,
            };
            dispatch("notification/addSimItem", item, { root: true });
            break;
          case WebSocketEvent.OTALog:
            const { otaLog, simId, loadFile, managedPackageLabel } = (data as OTALogPayload);
            const logField = JSON.parse(otaLog.logField);

            let actionType;
            const actionTypeId = logField.actionType.id as ActionTypeId;
            switch (actionTypeId) {
              case ActionTypeId.INSTALL_PACKAGE:
                actionType = "Install";
                break;
              case ActionTypeId.REMOVE_PACKAGE:
                actionType = "Uninstall";
                break;
              case ActionTypeId.STORE_DATA:
                actionType = "StoreData";
                break;
              case ActionTypeId.RFM:
                actionType = "RFM";
                break;
              default:
                actionType = "Unknown";
                break;
            }
            const notificationItem: NotificationItem = {
              date: otaLog.createdAt,
              title: "ReceivedOtaLog",
              body: `ICCID: ${otaLog.iccid}\nType: ${managedPackageLabel}\nName: ${loadFile.label}\nStatus: ${actionType} ${otaLog.status}`,
              to: `/sims/${simId}/${otaLog.iccid}`,
            };
            dispatch("notification/addOTALogItem", notificationItem, { root: true });

          case WebSocketEvent.CameraEvent:
            //デモ用 AIカメラの物体認識ログ
            interface CameraEventPayload {
              event: WebSocketEvent.CameraEvent;
              iccid: string;
              cameraEvent: ClassificationLogItemType;
            }

            const { iccid, cameraEvent } = data as CameraEventPayload;
            console.log(iccid, cameraEvent);
            dispatch("cameraEvent/addCameraEvent", cameraEvent, { root: true });
          default:
            // NOTE: NOOP
            break;
        }
      });
    },
    disconnect: ({ commit, state }: ActionContext<State, any>) => {
      console.debug(new Date(), "websocket/disconnect");
      if (!state.socket) {
        console.debug(new Date(), "websocket/disconnect", "websocket seems not connected. skip disconnect websocket");
        return;
      }
      commit("SET_CONNECT", false);
      console.debug(new Date(), "websocket/disconnect", "close websocket");
      state.socket.close();
      commit("SET_SOCKET", null);
    },
    sendKeepAlive: ({ state }: ActionContext<State, any>) => {
      console.debug(new Date(), "websocket/sendKeepAlive");
      if (!state.socket) {
        console.debug(new Date(), "websocket/disconnect", "websocket seems not connected. skip send keep alive message");
        return;
      }
      console.debug(new Date(), "websocket/disconnect", "send ping message");
      state.socket.send(JSON.stringify({ event: WebSocketEvent.Ping }));
    },
    startKeepAlive: ({ dispatch }: ActionContext<State, any>) => {
      console.debug(new Date(), "websocket/startKeepAlive", "interval:", 3 * 60 * 1000);
      setInterval(() => dispatch("sendKeepAlive"), 3 * 60 * 1000);
    },
  },
};
